import { Injectable } from "@angular/core";
import { TranslocoService } from "@ngneat/transloco";
import { environment } from "../environments/environment";

@Injectable()
export class NavigateToAccountService {

    public url='';
    protected activeLang = '';
    constructor(private _translateService : TranslocoService){
    }

    async getLanguage(){
        this.activeLang = await this._translateService.getActiveLang();
    }

    setParam(){
        if(environment.production){
            if(this.activeLang === 'en'){
                this.url = 'https://account.love69.eu';
            } else {
                this.url = 'https://cont.love69.ro';
            }
        } else {
            if(this.activeLang === 'en'){
                this.url = 'https://account-dev.love69.eu';
            } else {
                this.url = 'https://cont-dev.love69.ro';
            }
        }   
    }
}